import React, { FC } from 'react'
import SEO from '../../../../components/seo'
import { HeadingWrapper } from '../../../../components/HeadingWrapper/HeadingWrapper.styled'
import { Heading } from '../../../../components/Heading/Heading.styled'
import Wrapper from '../../../../components/Wrapper/Wrapper'
import { ErrorMessage, Formik } from 'formik'
import Input from '../../../../components/Input/Input'
import { Error, FormItem } from '../../../../components/FormElements/FormElements.styled'
import useAuth from '../../../../contexts/AuthContext/useAuth'
import Button from '../../../../components/Button/Button'
import useNavigate from '../../../../hooks/useNavigate'
import Tooltip from '../../../../components/Tooltip/Tooltip'
import { SubHeading } from '../../../../components/SubHeading/SubHeading.styled'
import { graphql } from 'gatsby'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { TagTypes, useAssignTagMutation } from '../../../../generated/api'
import { getAddTagSchema } from './validation'
import { IPageProps } from '../../../../contexts/PageContext'
import { useQueryClient } from '@tanstack/react-query'
import useGameRestricted from '../../../../hooks/useGameRestricted'
import Loading from '../../../../components/Loading'
import RestrictedPage from '../../../../components/RestrictedPage'

const AddCard: FC<IPageProps<Queries.AddCardQuery>> = () => {
  const { user } = useAuth()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const addTagSchema = getAddTagSchema(t)
  const queryClient = useQueryClient()
  const { isGameRestricted, isLoading, game } = useGameRestricted()

  const { mutateAsync: createTag } = useAssignTagMutation({
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ['GetPlayerById'] }),
  })

  if (isLoading) {
    return <Loading />
  }

  if (isGameRestricted) {
    return (
      <RestrictedPage
        title={t('addCard.title')}
        text={t('addCard.restrictedPage', { days: game?.info.restrictAtDays })}
      />
    )
  }

  return (
    <>
      <SEO title={t('addCard.seoTitle')} />
      <HeadingWrapper>
        <Heading>Add a card</Heading>
        <SubHeading as="h2" secondaryForm veryTall>
          {t('addCard.subtitle', { firstName: user?.profile.firstName })}
        </SubHeading>
      </HeadingWrapper>
      <Wrapper width="400px" textAlign="left">
        <Formik
          enableReinitialize
          initialValues={{
            playerId: user?.id || '',
            tagNumber: null,
            code: null,
            submit: null,
          }}
          validationSchema={addTagSchema}
          onSubmit={async (
            values: {
              playerId: string
              tagNumber: number | null
              code: string | null
              submit: string | null
            },
            { setErrors },
          ) => {
            try {
              if (user?.id) {
                await createTag({
                  input: {
                    tagNumber: values.tagNumber,
                    tagType: TagTypes.Card,
                    playerId: user?.id,
                    code: values?.code,
                  },
                })
                await navigate(`/signup/join-team`, { replace: true })
              }
            } catch (e) {
              console.log('err:', e?.message)
              if (e?.message.includes('This card is connected to different game')) {
                setErrors({ submit: t('errors.api.addCard.differentGame') || undefined })
              } else if (e?.message.includes('Card already used.')) {
                setErrors({ submit: t('errors.api.addCard.usedTag') || undefined })
              } else if (e?.message.includes('Activation code is required for a card tag.')) {
                setErrors({ submit: t('errors.api.addCard.codeRequired') || undefined })
              } else if (e?.message.includes('Activation code is not valid.')) {
                setErrors({ submit: t('errors.api.addCard.codeNotValid') || undefined })
              } else {
                setErrors({ submit: t('addCard.errorMessage') || undefined })
              }
            }
          }}
        >
          {({ values, handleChange, handleBlur, handleSubmit, isSubmitting, touched, errors }) => {
            return (
              <>
                <h2 style={{ marginBottom: '8%' }}>Card details for {user?.profile.firstName}</h2>
                <form onSubmit={handleSubmit} noValidate>
                  <FormItem>
                    <Input
                      type="text"
                      id="player"
                      autoComplete="player"
                      text={t('common.player') || ''}
                      value={`${user?.profile.firstName}`}
                      disabled
                      inputProps={{
                        title: 'player',
                      }}
                    />
                  </FormItem>
                  <FormItem>
                    <Input
                      type="number"
                      id="tagNumber"
                      text={t('addCard.cardNumber') || ''}
                      value={values.tagNumber}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.tagNumber ? errors?.tagNumber : undefined}
                      required
                      inputProps={{ min: 0 }}
                      tooltip={<Tooltip text={t('addCard.helperText') || ''} />}
                    />
                  </FormItem>
                  <FormItem>
                    <Input
                      type="text"
                      id="code"
                      text={t('addCard.activationCode') || ''}
                      value={values.code}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.code ? errors.code : undefined}
                      required
                    />
                  </FormItem>
                  <FormItem marginBottom={'2rem'}>
                    <Button
                      text={t('addCard.connectCard')}
                      secondary
                      type="submit"
                      isSubmitting={isSubmitting}
                      disabled={isSubmitting || !values.tagNumber || !values.code}
                    />
                  </FormItem>
                  <ErrorMessage name="submit" component={(props) => <Error className="checkboxError" {...props} />} />
                  <FormItem>
                    <Button
                      text={t('addCard.noCard')}
                      tertiary
                      type="button"
                      onClick={() => navigate('/signup/join-team')}
                    />
                  </FormItem>
                </form>
              </>
            )
          }}
        </Formik>
      </Wrapper>
    </>
  )
}

export const query = graphql`
  query AddCard($language: String) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      ...FLocales
    }
  }
`

export default AddCard
