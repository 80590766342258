exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-templates-about-us-index-tsx": () => import("./../../../src/templates/aboutUs/index.tsx" /* webpackChunkName: "component---src-templates-about-us-index-tsx" */),
  "component---src-templates-accessibility-index-tsx": () => import("./../../../src/templates/accessibility/index.tsx" /* webpackChunkName: "component---src-templates-accessibility-index-tsx" */),
  "component---src-templates-add-card-index-tsx": () => import("./../../../src/templates/addCard/index.tsx" /* webpackChunkName: "component---src-templates-add-card-index-tsx" */),
  "component---src-templates-app-terms-of-service-index-tsx": () => import("./../../../src/templates/appTermsOfService/index.tsx" /* webpackChunkName: "component---src-templates-app-terms-of-service-index-tsx" */),
  "component---src-templates-child-privacy-policy-index-tsx": () => import("./../../../src/templates/childPrivacyPolicy/index.tsx" /* webpackChunkName: "component---src-templates-child-privacy-policy-index-tsx" */),
  "component---src-templates-completed-survey-index-tsx": () => import("./../../../src/templates/completedSurvey/index.tsx" /* webpackChunkName: "component---src-templates-completed-survey-index-tsx" */),
  "component---src-templates-confirm-email-index-tsx": () => import("./../../../src/templates/confirmEmail/index.tsx" /* webpackChunkName: "component---src-templates-confirm-email-index-tsx" */),
  "component---src-templates-cookies-index-tsx": () => import("./../../../src/templates/cookies/index.tsx" /* webpackChunkName: "component---src-templates-cookies-index-tsx" */),
  "component---src-templates-faqs-index-tsx": () => import("./../../../src/templates/faqs/index.tsx" /* webpackChunkName: "component---src-templates-faqs-index-tsx" */),
  "component---src-templates-forgot-password-index-tsx": () => import("./../../../src/templates/forgotPassword/index.tsx" /* webpackChunkName: "component---src-templates-forgot-password-index-tsx" */),
  "component---src-templates-game-activities-index-tsx": () => import("./../../../src/templates/game/activities/index.tsx" /* webpackChunkName: "component---src-templates-game-activities-index-tsx" */),
  "component---src-templates-game-awards-index-tsx": () => import("./../../../src/templates/game/awards/index.tsx" /* webpackChunkName: "component---src-templates-game-awards-index-tsx" */),
  "component---src-templates-game-dashboard-index-tsx": () => import("./../../../src/templates/game/dashboard/index.tsx" /* webpackChunkName: "component---src-templates-game-dashboard-index-tsx" */),
  "component---src-templates-game-events-details-index-tsx": () => import("./../../../src/templates/game/events/details/index.tsx" /* webpackChunkName: "component---src-templates-game-events-details-index-tsx" */),
  "component---src-templates-game-events-index-tsx": () => import("./../../../src/templates/game/events/index.tsx" /* webpackChunkName: "component---src-templates-game-events-index-tsx" */),
  "component---src-templates-game-landing-index-tsx": () => import("./../../../src/templates/game/landing/index.tsx" /* webpackChunkName: "component---src-templates-game-landing-index-tsx" */),
  "component---src-templates-game-leaderboards-index-tsx": () => import("./../../../src/templates/game/leaderboards/index.tsx" /* webpackChunkName: "component---src-templates-game-leaderboards-index-tsx" */),
  "component---src-templates-game-map-index-tsx": () => import("./../../../src/templates/game/map/index.tsx" /* webpackChunkName: "component---src-templates-game-map-index-tsx" */),
  "component---src-templates-game-setup-index-tsx": () => import("./../../../src/templates/gameSetup/index.tsx" /* webpackChunkName: "component---src-templates-game-setup-index-tsx" */),
  "component---src-templates-get-started-index-tsx": () => import("./../../../src/templates/getStarted/index.tsx" /* webpackChunkName: "component---src-templates-get-started-index-tsx" */),
  "component---src-templates-home-tsx": () => import("./../../../src/templates/home.tsx" /* webpackChunkName: "component---src-templates-home-tsx" */),
  "component---src-templates-how-to-play-index-tsx": () => import("./../../../src/templates/howToPlay/index.tsx" /* webpackChunkName: "component---src-templates-how-to-play-index-tsx" */),
  "component---src-templates-join-game-index-tsx": () => import("./../../../src/templates/joinGame/index.tsx" /* webpackChunkName: "component---src-templates-join-game-index-tsx" */),
  "component---src-templates-login-index-tsx": () => import("./../../../src/templates/login/index.tsx" /* webpackChunkName: "component---src-templates-login-index-tsx" */),
  "component---src-templates-manage-account-index-tsx": () => import("./../../../src/templates/manageAccount/index.tsx" /* webpackChunkName: "component---src-templates-manage-account-index-tsx" */),
  "component---src-templates-newsletter-signup-index-tsx": () => import("./../../../src/templates/newsletterSignup/index.tsx" /* webpackChunkName: "component---src-templates-newsletter-signup-index-tsx" */),
  "component---src-templates-newsletter-signup-newsletter-success-tsx": () => import("./../../../src/templates/newsletterSignup/newsletter-success.tsx" /* webpackChunkName: "component---src-templates-newsletter-signup-newsletter-success-tsx" */),
  "component---src-templates-privacy-policy-index-tsx": () => import("./../../../src/templates/privacyPolicy/index.tsx" /* webpackChunkName: "component---src-templates-privacy-policy-index-tsx" */),
  "component---src-templates-resources-for-schools-index-tsx": () => import("./../../../src/templates/resourcesForSchools/index.tsx" /* webpackChunkName: "component---src-templates-resources-for-schools-index-tsx" */),
  "component---src-templates-signup-age-gate-select-age-tsx": () => import("./../../../src/templates/signup/ageGate/select-age.tsx" /* webpackChunkName: "component---src-templates-signup-age-gate-select-age-tsx" */),
  "component---src-templates-signup-age-gate-under-age-tsx": () => import("./../../../src/templates/signup/ageGate/under-age.tsx" /* webpackChunkName: "component---src-templates-signup-age-gate-under-age-tsx" */),
  "component---src-templates-signup-details-add-card-index-tsx": () => import("./../../../src/templates/signup/details/addCard/index.tsx" /* webpackChunkName: "component---src-templates-signup-details-add-card-index-tsx" */),
  "component---src-templates-signup-details-create-team-index-tsx": () => import("./../../../src/templates/signup/details/createTeam/index.tsx" /* webpackChunkName: "component---src-templates-signup-details-create-team-index-tsx" */),
  "component---src-templates-signup-details-create-team-success-tsx": () => import("./../../../src/templates/signup/details/createTeam/success.tsx" /* webpackChunkName: "component---src-templates-signup-details-create-team-success-tsx" */),
  "component---src-templates-signup-details-game-details-index-tsx": () => import("./../../../src/templates/signup/details/gameDetails/index.tsx" /* webpackChunkName: "component---src-templates-signup-details-game-details-index-tsx" */),
  "component---src-templates-signup-details-join-team-index-tsx": () => import("./../../../src/templates/signup/details/joinTeam/index.tsx" /* webpackChunkName: "component---src-templates-signup-details-join-team-index-tsx" */),
  "component---src-templates-signup-details-name-details-index-tsx": () => import("./../../../src/templates/signup/details/nameDetails/index.tsx" /* webpackChunkName: "component---src-templates-signup-details-name-details-index-tsx" */),
  "component---src-templates-signup-details-profile-details-index-tsx": () => import("./../../../src/templates/signup/details/profileDetails/index.tsx" /* webpackChunkName: "component---src-templates-signup-details-profile-details-index-tsx" */),
  "component---src-templates-signup-family-add-tag-index-tsx": () => import("./../../../src/templates/signup/family/addTag/index.tsx" /* webpackChunkName: "component---src-templates-signup-family-add-tag-index-tsx" */),
  "component---src-templates-signup-family-create-member-index-tsx": () => import("./../../../src/templates/signup/family/createMember/index.tsx" /* webpackChunkName: "component---src-templates-signup-family-create-member-index-tsx" */),
  "component---src-templates-signup-family-family-success-tsx": () => import("./../../../src/templates/signup/family/family-success.tsx" /* webpackChunkName: "component---src-templates-signup-family-family-success-tsx" */),
  "component---src-templates-signup-family-invite-family-tsx": () => import("./../../../src/templates/signup/family/invite-family.tsx" /* webpackChunkName: "component---src-templates-signup-family-invite-family-tsx" */),
  "component---src-templates-signup-family-join-family-tsx": () => import("./../../../src/templates/signup/family/join-family.tsx" /* webpackChunkName: "component---src-templates-signup-family-join-family-tsx" */),
  "component---src-templates-signup-family-join-team-index-tsx": () => import("./../../../src/templates/signup/family/joinTeam/index.tsx" /* webpackChunkName: "component---src-templates-signup-family-join-team-index-tsx" */),
  "component---src-templates-signup-family-select-age-tsx": () => import("./../../../src/templates/signup/family/selectAge.tsx" /* webpackChunkName: "component---src-templates-signup-family-select-age-tsx" */),
  "component---src-templates-signup-index-tsx": () => import("./../../../src/templates/signup/index.tsx" /* webpackChunkName: "component---src-templates-signup-index-tsx" */),
  "component---src-templates-signup-success-tsx": () => import("./../../../src/templates/signup/success.tsx" /* webpackChunkName: "component---src-templates-signup-success-tsx" */),
  "component---src-templates-terms-and-conditions-index-tsx": () => import("./../../../src/templates/termsAndConditions/index.tsx" /* webpackChunkName: "component---src-templates-terms-and-conditions-index-tsx" */)
}

