import React, { FC, useEffect } from 'react'
import Wrapper from '../../../../components/Wrapper/Wrapper'
import Button from '../../../../components/Button/Button'
import SEO from '../../../../components/seo'
import { Error, FormItem } from '../../../../components/FormElements/FormElements.styled'
import { HeadingWrapper } from '../../../../components/HeadingWrapper/HeadingWrapper.styled'
import { Heading } from '../../../../components/Heading/Heading.styled'
import { SubHeading } from '../../../../components/SubHeading/SubHeading.styled'
import { ErrorMessage, Formik } from 'formik'
import Input from '../../../../components/Input/Input'
import useNavigate from '../../../../hooks/useNavigate'
import useAuth from '../../../../contexts/AuthContext/useAuth'
import useChild from '../../../../hooks/useChild/useChild'
import Tooltip from '../../../../components/Tooltip/Tooltip'
import { graphql } from 'gatsby'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { getAddTagSchema } from './validation'
import useJoinGame from '../../../../hooks/useJoinGame/useJoinGame'
import { TagTypes, useAssignTagMutation } from '../../../../generated/api'
import useGameRestricted from '../../../../hooks/useGameRestricted'
import Loading from '../../../../components/Loading'
import RestrictedPage from '../../../../components/RestrictedPage'

const AddTag: FC = () => {
  const { masterUser } = useAuth()
  const { child } = useChild()
  const join = useJoinGame()
  const { isGameRestricted, isLoading, game } = useGameRestricted()

  const { mutateAsync: createTag } = useAssignTagMutation()

  const navigate = useNavigate()
  const { t } = useTranslation()
  const addTagSchema = getAddTagSchema(t)

  useEffect(() => {
    if (!child && !isGameRestricted && !isLoading) {
      navigate(`/`)
    }
  }, [isGameRestricted, isLoading])

  if (isLoading) {
    return <Loading />
  }

  if (isGameRestricted) {
    return (
      <RestrictedPage
        title={t('addCard.title')}
        text={t('addCard.restrictedPage', { days: game?.info.restrictAtDays })}
      />
    )
  }

  return (
    <>
      <SEO title={t('addCard.seoTitle')} />
      <HeadingWrapper>
        <Heading>{t('addCard.title')}</Heading>
        <SubHeading as="h2" secondaryForm veryTall>
          {t('addCard.subtitle', { firstName: child?.profile.firstName })}
        </SubHeading>
      </HeadingWrapper>
      <Wrapper width="400px" textAlign="left">
        <Formik
          enableReinitialize
          initialValues={{
            gameId: masterUser?.info.gameId,
            tagNumber: undefined,
            consent: '',
            code: '',
            submit: null,
          }}
          validationSchema={addTagSchema}
          onSubmit={async (values, { setErrors }) => {
            try {
              if (!values.gameId) return
              await join({
                gameId: values.gameId,
                consentText: values.consent,
              })
              if (child?.id) {
                await createTag({
                  input: {
                    playerId: child?.id,
                    tagType: TagTypes.Card,
                    tagNumber: values.tagNumber,
                    code: values.code,
                  },
                })
              }
              await navigate(`/signup/family/join-team`, { replace: true })
            } catch (e) {
              console.log('err:', e?.message)
              if (e?.message.includes('This card is connected to different game')) {
                setErrors({ submit: t('errors.api.addCard.differentGame') || undefined })
              } else if (e?.message.includes('Card already used.')) {
                setErrors({ submit: t('errors.api.addCard.usedTag') || undefined })
              } else if (e?.message.includes('Activation code is required for a card tag.')) {
                setErrors({ submit: t('errors.api.addCard.codeRequired') || undefined })
              } else if (e?.message.includes('Activation code is not valid.')) {
                setErrors({ submit: t('errors.api.addCard.codeNotValid') || undefined })
              } else {
                setErrors({ submit: t('addCard.errorMessage') || undefined })
              }
            }
          }}
        >
          {({ values, handleChange, handleBlur, handleSubmit, isSubmitting, touched, errors }) => {
            return (
              <form onSubmit={handleSubmit} noValidate>
                <FormItem>
                  <Input
                    type="text"
                    id="player"
                    autoComplete="player"
                    text={t('common.player') || ''}
                    value={`${child?.profile.firstName}`}
                    disabled
                    inputProps={{
                      title: 'player',
                    }}
                    tooltip={<Tooltip text={t('addCard.helperText') || ''} />}
                  />
                </FormItem>
                <FormItem>
                  <Input
                    type="number"
                    id="tagNumber"
                    text={t('addCard.cardNumber') || ''}
                    value={values.tagNumber}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.tagNumber ? errors?.tagNumber : undefined}
                    required
                    inputProps={{ min: 0 }}
                  />
                </FormItem>
                <FormItem>
                  <Input
                    type="text"
                    id="code"
                    text={t('addCard.activationCode') || ''}
                    value={values.code}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.code ? errors.code : undefined}
                    required
                  />
                </FormItem>
                <FormItem marginBottom={'2rem'}>
                  <Button
                    text={t('addCard.connectCard')}
                    secondary
                    type="submit"
                    isSubmitting={isSubmitting}
                    disabled={isSubmitting || !values.tagNumber || !values.code}
                  />
                </FormItem>
                <ErrorMessage name="submit" component={(props) => <Error className="checkboxError" {...props} />} />
                <FormItem>
                  <Button
                    text={t('addCard.noCard')}
                    tertiary
                    type="button"
                    onClick={() => navigate(`/signup/family/join-team`, { replace: true })}
                  />
                </FormItem>
              </form>
            )
          }}
        </Formik>
      </Wrapper>
    </>
  )
}

export const query = graphql`
  query AddTag($language: String) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      ...FLocales
    }
  }
`

export default AddTag
