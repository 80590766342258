import { number, object, string } from 'yup'

export const getConnectCardSchema = (t) => {
  const t2 = (...args: any) => t(...args) || undefined
  return object({
    tagNumber: number()
      .required(t('gameSetup.connectCard.errors.noCardNumber'))
      .min(1, t('gameSetup.connectCard.errors.wrongCardNumber'))
      .max(99999999, t('gameSetup.connectCard.errors.wrongCardNumber')),
    code: string().required(t('gameSetup.connectCard.errors.code')).nullable(),
  })
}
