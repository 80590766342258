/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { FC } from 'react'
import Helmet from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

const GATSBY_APPZI_TOKEN = process.env.GATSBY_APPZI_TOKEN

interface MetaProps {
  name: string
  content: string
}

interface SEOProps {
  description?: string
  lang?: string
  meta?: MetaProps[]
  title: string
}

const SEO: FC<SEOProps> = ({ description = '', lang = 'en', meta = [], title }) => {
  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
          siteUrl
        }
      }
    }
  `)

  const metaDescription = description || site.siteMetadata.description
  const scripts: Record<string, any> = []
  if (GATSBY_APPZI_TOKEN) {
    scripts.push({
      src: `https://app.appzi.io/bootstrap/bundle.js?token=${GATSBY_APPZI_TOKEN}`,
      async: true,
      type: 'text/javascript',
    })
  }

  const image = `${site.siteMetadata.siteUrl}/images/seo/bts-social-share-image.png`

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      defaultTitle={site.siteMetadata.title}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: 'og:image',
          content: image,
        },
        {
          property: 'og:image:width',
          content: 1200,
        },
        {
          property: 'og:image:height',
          content: 628,
        },
        {
          name: 'twitter:card',
          content: 'summary_large_image',
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata?.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `twitter:image`,
          content: image,
        },
      ].concat(meta)}
      script={scripts}
    >
      <link rel="stylesheet" href="https://use.typekit.net/mqi7oox.css"></link>
    </Helmet>
  )
}

export default SEO
